:root{
    --teal-col : rgba(23, 120, 116)
}

body {
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-weight: 700;
}

.header { 
    position: fixed;
    z-index:2;
    height: 120px !important;
    background-color: var(--teal-col);
    background-repeat: no-repeat;
    background-size: contain;
    transition: background-color 200ms linear;
}

.header-logo {
    height: 5rem;
    width: 20rem;
    background-repeat: no-repeat;
    background-size: contain;
}

.home-banner { 
    height: 100vh;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:0!important;
    margin:0!important;

}

.haknova-ocean-rescue, .haknova-ruth-photo{
    min-height: 60vh;
    height: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.haknova-ocean-rescue{
    background-position: 70% 0%;
} 

.haknova-ruth-photo{
    background-position: 30% 0%;
    
}

.haknova-ruth-signature-photo{
    min-height: 17vh;
    height: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rescue-text{
    font-size: 1.5rem;
    font-weight: 700;
    padding: 4rem 0 !important;
    line-height: 1.2;
    letter-spacing: 1px;
    text-align: left;
}

.home-banner-text {
    padding-top: 8rem;
    font-weight: bolder;
    word-spacing: 5px;
    letter-spacing: 5px;
    max-width: 70%;
    font-size: 5rem;
    text-shadow: 0px 2px 10px #170000c5;
}

.nav-link{
    font-weight: bold;
    color:rgb(0, 0, 0) !important;
}


.navbar-nav > .active{
    color:white !important;
}

.anchor-margin {
    position: absolute;
    margin-top: -118px;
}

.ocean-banner { 
    height: 25vh;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

}

.ocean-banner-text {
    font-weight: bolder;
    word-spacing: 5px;
    letter-spacing: 1px;
    max-width: 70%;
    font-size: 2rem;
    text-shadow: 0px 2px 4px #17000075;
}

.mission-text {
    font-size: 1.5rem;
    font-weight: 700;
    padding: 4rem 0 !important;
    line-height: 1.2;
    letter-spacing: 1px;
    text-align: left;
}

.divider {
    width:100%;
    height:3px;
    background-color: rgba(189, 189, 189, 0.568);
}

.section{
    padding: 2rem 0 0 0 !important;
}

.section-heading{
    color: var(--teal-col);
    font-size:1.7rem;
    font-weight: 700;
    letter-spacing: 1px;
}

.ruth-text{
    font-size: 1.5rem;
    font-weight: 700;
    padding: 4rem 0 !important;
    line-height: 1.2;
    letter-spacing: 1px;
    text-align: left;
}

.ruth-quote {
    font-weight: 400;
    font-style: italic;

}

footer {
    min-height: 5vh;
    height: 100%;
    overflow: hidden;
    background-color: var(--teal-col);
    display: flex;
}

.mobile-menu {
    position: fixed;
    z-index: 3;
    background-color: var(--teal-col);
    font-size: 1.5rem;
}

.close-button{
    position: absolute;
    top:0;
    right:0;
    z-index: 5;
    height: 4rem;
    width: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-button-icon {
    height: 2rem;
    width: 2rem;
}

@media screen and (min-width: 1023px) and (max-width:1025px) {
    
    .haknova-ruth-photo{
        background-size: contain;
    }
}

@media screen and (max-width: 992px) {
    .home-banner-text{
        font-size: 4rem;
        line-height: 1.1;
    }

    .rescue-text, .mission-text, .ruth-text{
        font-size: 1.3rem;
        padding: 3rem 0 !important;
    }


    .haknova-ocean-rescue{
        background-position: center;
    } 
}

@media screen and (max-width: 768px) {
    .home-banner-text{
        font-size: 3rem;
    }

    .rescue-text, .mission-text, .ruth-text{
        font-size: 1.2rem;
    }

    .section-heading{
        font-size: 1.3rem;
    }

    .haknova-ruth-photo{
        min-height: 100vh;
    }
}

@media screen and (max-width: 575px) {
    .home-banner-text{
        font-size: 2.4rem;
    }

    .rescue-text,.mission-text, .ruth-text{
        font-size: 1rem;
    }

    .haknova-ruth-signature-photo{
        min-height: 12vh;
    }


}