body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "Futura";
  src: local("Futura"), url(./fonts/FuturaBookFont.ttf) format("truetype");
}

@font-face {
  font-family: "NewComicTitle";
  src: local("NewComicTitle"), url(./fonts/NewComicTitle.ttf) format("truetype");
}

:root {
  --light-gray: rgba(223, 221, 221, 255);
  --dark-gray: rgba(179, 179, 179, 255);
  --darker-gray: rgba(119, 119, 119, 255);
  --darkest-gray: rgba(72, 72, 72, 255);
}

.bg-dark-gray {
  background-color: var(--dark-gray);
}

.br-1rem {
  border-radius: 1rem;
}

.haknova-emblem-logo {
  margin: 2rem;
  height: 15rem;
}
