.message-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: var(--dark-gray);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.message-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    padding: 1rem;
    /* border-bottom: solid;
    border-bottom-width: 0.5px;
    border-bottom-color: white; */
}

.message-close-button {
    height: 1.2rem;
    width: 1.2rem;
    cursor: pointer;
}

.message-body {
    border-color: var(--dark-gray);
    border-style: solid;
    border-width: 0 5px 5px 5px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    background-color: white;
    padding: 1rem;
    white-space: pre-wrap;
    overflow-y: auto;
}

.gray-btn{
    width: fit-content;
}
.attachment-button {
    border: 0;
    background: transparent;
    width: fit-content;
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.attachment-button img {
    height: 40px;
    margin-left: 10px;
}
.message-body .react-transform-wrapper {
    width: 100% !important;
}
.message-body .tools {
    text-align: center;
}
.message-body .tools button {
    padding: 10px;
    width: 100px;
}
.message-body .tools button:first-child {
    border-right: 0;
}