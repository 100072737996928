.message-list-container {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    scrollbar-color: var(--darkest-gray) var(--dark-gray);
    scrollbar-width: thin;
}

.message-list-container::-webkit-scrollbar {
    width: 8px;
}

.message-list-container::-webkit-scrollbar-track {
    background-color: var(--dark-gray);
}

.message-list-container::-webkit-scrollbar-thumb {
    background-color: var(--darkest-gray);
}

.message-card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--dark-gray);
    margin-top: 2rem;
    padding: 1rem;
    border-radius: 1rem;
    cursor: pointer;
    font-size: 1.3rem;
    font-weight: 500;
}

.message-card:nth-last-child(1) {
    margin-bottom: 2rem;
}

.message-card-unread {
    font-weight: 700;
}

.message-card-name {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-right-width: 2px;
    border-right-color: var(--darkest-gray);
    border-right-style: solid;
    padding: 1rem;
    text-transform: capitalize;
}

.message-card-content {
    padding: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.message-card-time {
    white-space: nowrap;
    margin-left: auto;
}

@media screen and (max-width: 1023px) {
    .message-card {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
    .message-card {
        font-size: 1.1rem;
    }
    .message-card-time {
        display: none;
    }
}

@media screen and (max-width: 575px) {
    .message-card {
        font-size: 1rem;
    }
}
