body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    width: 100vw;
}

.login-container {
    min-height: 100%;
    width: 100%;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: auto;
    padding: 3rem 0;
}

.haknova-supernova-logo {
    max-width: 45%;
}

.login-message {
    font-family: NewComicTitle;
    width: 40%;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 1.5rem;
    text-transform: uppercase;
    padding: 1.5rem 0;
    letter-spacing: 1px;
}

.login-credentials {
    background-color: black;
    border-radius: 2rem;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-credentials-title {
    color: rgba(230, 194, 86, 255);
    font-weight: bold;
    font-size: 1.3rem;
    margin-bottom: 1rem;
}

.cred-input {
    margin: 0.7rem 0;
    padding: 0.3rem;
}

.enter-btn {
    background-color: rgba(240, 82, 105, 255);
    color: #000;
    margin: 1rem 0;
    padding: 0.1rem 3rem;
    border-radius: 0.5rem;
    box-shadow: none;
    border-width: 0;
    font-weight: bold;
    font-size: 1.2rem;
}

.invalid-text {
    color: rgba(240, 82, 105, 255);
    font-weight: 600;
}

@media screen and (max-width: 1024px) {
    .haknova-supernova-logo {
        max-width: 55%;
    }
    .login-message {
        width: 50%;
    }
}

@media screen and (max-width: 992px) {
    .haknova-supernova-logo {
        max-width: 65%;
    }
    .login-message {
        width: 60%;
    }
}

@media screen and (max-width: 768px) {
    .haknova-supernova-logo {
        max-width: 75%;
    }
    .login-message {
        width: 70%;
    }
}

@media screen and (max-width: 575px) {
    .haknova-supernova-logo {
        max-width: 85%;
    }
    .login-message {
        font-size: 1.3rem;
        width: 80%;
    }
}
