.contact-form-container {
    background-color: var(--light-gray);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.mailbox {
    display: flex;
    margin: auto;
    width: 100%;
    max-width: 70%;
    height: 80%;
}

.content-placeholder {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    padding: 2rem 1rem 1rem 1rem;
    line-height: 3rem;
}

.gray-btn {
    background-color: var(--darkest-gray);
    color: white;
    margin: 1rem 0;
    padding: 0.3rem 1rem 0.3rem 1rem;
    border-radius: 0.5rem;
    box-shadow: none;
    border-width: 0;
    font-weight: 600;
    font-size: 1.2rem;
}

/* compose styles */
.compose-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    background-color: var(--dark-gray);
    border-radius: 1rem;
}

.compose-text-area::-webkit-scrollbar {
    width: 1rem;
}

.compose-text-area::-webkit-scrollbar-track {
    background-color: var(--dark-gray);
}

.compose-text-area::-webkit-scrollbar-thumb {
    background-color: var(--darkest-gray);
}

.compose-text-area {
    width: 90%;
    height: 100%;
    resize: none;
    scrollbar-color: var(--darkest-gray) var(--dark-gray);
}

.compose-footer {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dailog-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 70%;
    z-index: 2;
}

.confirm-dailog {
    background-color: var(--dark-gray);
    padding: 1rem;
    box-shadow: 0px 1px 7px 2px #2222;
    border-radius: 0.5rem;
    max-width: 320px;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.8rem;
    text-align: center;
}

.confirm-dailog-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.sending-message {
    height: 100%;
    width: 100%;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    background-color: #fff4;
}

@media screen and (max-width: 1023px) {
    .contact-form-container {
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .mailbox {
        max-width: 80%;
    }

    .content-placeholder {
        font-size: 1.4rem;
        padding: 2rem 1rem 1rem 1rem;
        line-height: 2.5rem;
    }

    .confirm-dailog {
        padding: 1rem;
        max-width: 320px;
        font-size: 1.4rem;
        line-height: 1.6rem;
    }

    .dailog-wrapper {
        width: 80%;
    }
}

@media screen and (max-width: 992px) {
    .mailbox {
        max-width: 90%;
    }

    .dailog-wrapper {
        width: 90%;
    }

    .sending-message {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 768px) {
    .mailbox {
        max-width: 95%;
    }

    .content-placeholder {
        font-size: 1.3rem;
        padding: 2rem 1rem 1rem 1rem;
        line-height: 2rem;
    }

    .compose-text-area {
        width: 95%;
    }

    .confirm-dailog {
        padding: 1rem;
        font-size: 1.2rem;
        line-height: 1.6rem;
    }

    .confirm-dailog-footer > .gray-btn {
        font-size: 1rem;
    }

    .dailog-wrapper {
        width: 95%;
    }
}

@media screen and (max-width: 575px) {
    .mailbox {
        max-width: 98%;
    }

    .dailog-wrapper {
        width: 90%;
    }

    .confirm-dailog {
        padding: 1rem;
        font-size: 1rem;
        line-height: 1.4rem;
    }

    .content-placeholder {
        font-size: 1rem;
        padding: 1rem 1rem 0rem 1rem;
        line-height: 1.5rem;
    }

    .dailog-wrapper {
        width: 98%;
    }

    .sending-message {
        font-size: 1.1rem;
    }
}
