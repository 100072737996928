.drawer-container {
    background-color: var(--darker-gray);
    height: 100%;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.drawer-container-expanded {
    width: 15%;
}

.drawer-toggler {
    color: white;
    height: 4rem;
    line-height: 3rem;
    font-weight: 100;
    padding: 1rem;
}

.drawer-toggler:hover {
    cursor: pointer;
}

.drawer-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
}

.drawer-menu-item {
    color: white;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 1.6rem 1rem;
    background-color: transparent;
    border: 0px;
}

.drawer-menu-item:disabled {
    color: gray;
    cursor: not-allowed !important;
}

.drawer-menu-item-active {
    font-weight: bold;
}

.drawer-menu-item:hover {
    cursor: pointer;
}

.selected {
    font-weight: 700;
}

.new-message-alert {
    height: 1.2rem;
    width: 1.2rem;
    align-self: center;
    margin: 0.5rem;
}
.drawer-mobile-header {
    display: none;
}

.menu-divider {
    display: none;
}

.hamburger-icon {
    display: none;
}

.mobile-heading {
    display: none;
}

@media screen and (max-width: 1023px) {
    .drawer-container {
        background-color: var(--darker-gray);
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
        align-items: flex-end;
    }

    .drawer-toggler {
        display: none;
    }

    .mobile-heading {
        font-size: 1.3rem;
        color: white;
        font-weight: 500;
        text-align: center;
        width: 100%;
        display: inline;
        position: absolute;
        top: 0;
        padding-top: 0.5rem;
    }

    .hamburger-icon {
        display: block;
        color: white;
        height: 3rem;
        line-height: 3rem;
        font-weight: 100;
        padding: 1rem;
        z-index: 5;
    }

    .menu-container {
        width: 60%;
    }

    .drawer-menu {
        position: absolute;
        z-index: 5;
        top: 0;
        background-color: var(--darker-gray);
    }

    .drawer-mobile-header {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }

    .drawer-close-button {
        height: 1.4rem;
        width: 1.4rem;
        cursor: pointer;
        margin: 1rem;
    }

    .menu-divider {
        display: block;
        background-color: white;
        min-width: 100%;
        height: 2px;
    }
}
